import React from "react";
import { Link, graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";
import Image from "gatsby-plugin-sanity-image";
import { Seo } from "../components/Seo";
import { getSiblingProps, urlFor } from "../utils";
import { PortableTextComponents } from "../components/PortableTextComponents";
import { ArticleBackground } from "../components/layout/ArticleBackground";
import { blogBaseUrl } from "../constants";

export default function BlogPostPage({ data, pageContext }) {
  const {
    post,
    site: {
      siteMetadata: { siteUrl },
    },
  } = data;

  const defaultCoverUrl = new URL(data.defaultCover.publicURL, siteUrl).href;
  const coverPictureURL = urlFor(post.mainImage.asset) || defaultCoverUrl;

  const previous = getSiblingProps(pageContext.previous);
  const next = getSiblingProps(pageContext.next);

  const { title, publishedAt, excerpt, body } = post;

  const BlogPost = () => {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg" aria-hidden="true">
            <ArticleBackground />
          </div>
        </div>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-gray-500">
                {publishedAt}
              </span>
              <span className="mt-3 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </span>
            </h1>
          </div>
          <div className="mt-12 prose prose-action prose-lg text-gray-500 mx-auto">
            <figure>
              <Image
                {...post.mainImage}
                className="w-full rounded-lg overflow-hidden"
                width={1280}
              />
            </figure>
            <section
              id="articleBody"
              className="markdown text-lg max-w-prose mx-auto mt-16"
            >
              <PortableText value={body} components={PortableTextComponents} />
            </section>

            <div className="mt-12 prose prose-action prose-lg text-gray-500 mx-auto lg:flex lg:items-center lg:justify-between">
              {previous && (
                <Link
                  className="block mr-4 mt-4"
                  to={`${blogBaseUrl}${previous.slug}/`}
                  rel="prev"
                >
                  ← {"\u00A0" + previous.title}
                </Link>
              )}
              {next && (
                <Link
                  className="block mt-4 text-right"
                  to={`${blogBaseUrl}${next.slug}/`}
                  rel="next"
                >
                  {next.title + "\u00A0"}→
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Seo
        title={title}
        description={excerpt}
        image={coverPictureURL}
        isArticle={true}
      />
      <Header />
      <BlogPost />
      <BannerImage />
      <Footer />
    </div>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl: url
      }
    }
    defaultCover: file(
      relativePath: { regex: "/default_article_image.jpg/gm" }
    ) {
      publicURL
    }
    post: sanityBlog(slug: { current: { eq: $slug } }) {
      id
      title
      mainImage {
        ...ImageWithPreview
        alt
      }
      body: _rawBody(resolveReferences: { maxDepth: 4 })
      publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
    }
  }
`;
